<template>
    <div>
      
        <b-card>
        <form @submit.prevent="changeSpDetail()" action="">
         <b-row>
            <b-col>
              <span class="f-w-900"> Service Provider Name </span>
              <b-input
                type="text"
                label="Service Provider Name"
                placeholder="Service Provider Name"
                v-model="spData.name"
                maxlength="100"
                required
              >
          </b-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-3">
              <b-button
              type="submit"
              :disabled="isBusy"
              variant="primary"
            >
              {{ isEdit ? "Edit" : "Add" }}
            </b-button>
            </b-col>
          </b-row>
        </form>
        </b-card>
    </div>
  </template>
  
  <script>
  import SPService from "../../../../services/ServiceProviderServices";
  export default {
    props:{spdetail:Object,spModal:Boolean,isEdit:Boolean},
    data() {
      return {
        isBusy: false,
        spData: {},
      };
    },
    mounted() {
      if(this.isEdit){
        this.spData = this.spdetail;
      }
    },
    methods: {
      async changeSpDetail() {
        if (this.isEdit) {
          await this.editSpData();
        } else {
          await this.addSpData();
        }
      },
      async editSpData() {
        this.isBusy = true;
        let payload = {
          spid: this.spData._id,
          changeBody: {
            name: this.spData.name,
          },
        };
        let response = await SPService.update(payload);
        // this.$root.$emit("splist", {});
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000,
        onComplete: () => {
          this.$emit('closemodal')
          this.$bvModal.hide("openSPDetail");
          this.isBusy = false;
        }});
      },
      async addSpData() {
        this.isBusy = true;
        let response = await SPService.add(this.spData);
        // console.log(response);
        this.$emit('closeSPDetail')
        // this.$bvModal.hide("openSPDetail");
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000,
        onComplete: () => {
          this.$emit('closeSPDetail')
          this.isBusy = false;
        }});
      },
    },
  };
  </script>
  
  <style scoped>
  .hidden_header {
    display: none;
  }
  div {
    margin: auto 0;
    width: 100%;
  }
  </style>