<template>
    <div>
      <Breadcrumbs main="" title="Service Provider" />
        <b-row>
          <b-col xl="12" md="12">
            <b-card title="" type="gradient-red" sub-title="" class="mb-4">
              <b-row class="col-md-12">
                <b-col>
                  <button class="btn btn-dark btn-lg mb-4" variant="dark" @click="addProvider()">
                  Add Provider</button>
                </b-col>

              <b-col class="text-right col-md-5 col-12">
                  <b-form-group
                    label-cols="8"
                    label="Per page"
                    class="md-0"
                    >
                    <b-form-select
                      class="col-md-10"
                      v-model="perPage"
                      :options="pageOptions"
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                    <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
              <div v-else class="table-responsive datatable-vue">
              <b-table 
                striped hover 
                :items="spData" 
                stacked="md"
                :filter="filter"
                :current-page="currentPage"
                :per-page="perPage"
                @filtered="onFiltered"
                :fields="spFields"
                >
                <template #cell(name)="row">
                  <span class="font-weight-bold">{{ sentenceCase(row.item.name) }}</span>
                </template>
                
                <template #cell(status)="row">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>
                </template>
                <template #cell(actions)="row">
                    <!-- <feather class="text-danger" type="trash-2" @click="deleteSPDetail(row.item._id)"></feather>
                    <feather class="text-info ml-2" type="edit" @click="openSPDetail(row.item)"></feather>  -->
                  <button
                    size="sm"
                    @click="openSPDetail(row.item)"
                    class="btn btn-outline-primary btn-xs mr-1"
                  >
                  <feather type="edit"></feather>
                    Edit
                  </button>
                  <button
                    size="sm"
                    @click="deleteSPDetail(row.item)"
                    class="btn btn-outline-danger btn-xs"
                  >
                  <feather type="trash-2"></feather>
                    Delete
                  </button>
                </template>
              </b-table>
            </div>
            <b-col md="6" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-card>
          </b-col>
        </b-row>
        <!-- Service provider Modal -->
        <b-modal v-model="spModal" id="openSPDetail" title="Service Provider" @hidden="closeModal" class="theme-modal" size="xl" no-close-on-backdrop hide-footer>
          <spdetail :spdetail="spdetail" :spModal="spModal" :isEdit="isEdit" @closeSPDetail="closeModal"></spdetail>
          <!-- <template #modal-footer>
                <div>
                  <b-button class="float-right mr-2" @click="closeModal()">
                    Close
                  </b-button>
                </div>
              </template> -->
        </b-modal>

      <b-container fluid class="mt--7">
        <b-row class="justify-content-center">
          <b-col lg="12"> </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
  import SPService from "../../../../services/ServiceProviderServices";
  import spdetail from "./AddServiceProvider.vue";
  
  export default {
    components: {
      spdetail,
    },
    mounted() {
      this.userinfo = JSON.parse(window.localStorage.getItem("userinfo"));
      // if (this.userinfo.role != "manager") {
      //   this.$router.push("/auth/login");
      // }
      this.init();
    },
    data() {
      return {
        spModal: false,
        spFields: [
          {
            key: "name",
            label: "Name",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "actions",
            label: "Action",
          },
        ],
        spData: [],
        userinfo:{},
        spdetail:{},
        isEdit: false,
        loading: false,
        totalRows: 0,
        filter: null,
        currentPage: 1,
        perPage: 20,
        pageOptions: [20, 50, 100, 200],
      };
    },
    watch: {
      spModal: function (val) {
        if (val == false) {
          this.init();
        }
      },
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      closeModal() {
        this.spModal = false;
        this.init();
        this.isEdit = false;
      },
      openSPDetail(data) {
        this.spdetail = data;
        this.isEdit = true;
        this.spModal = true;
      },
      addProvider() {
        this.isEdit = false;
        this.spdetail = {};
        this.spModal = true;
      },
      async deleteSPDetail(data) {
        let payload = {
          spid: data._id,
        };
        if (confirm("Are you sure you want to delete")) {
          let response = await SPService.remove(payload);
          let variant = response.result ? "success" : "error";
          this.init();
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        }
      },
      async changeStatus(data) {
        if (data.status == false) {
          data.status = true;
        } else {
          data.status = false;
        }
        let payload = {
          spid: data._id,
          changeBody: {
            status: data.status,
          },
        };
        let response = await SPService.update(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: variant, duration: 2000});
        
      },
      async init() {
        this.loading = true;
        let response = await SPService.get();
        if (response.result) {
          this.spData = response.data.reverse();
          this.totalRows = this.spData.length;
        }
        this.loading = false;
      },
      sentenceCase(str) {
          if (typeof str !== 'string') return str
          return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())
        },
    },
  };
  </script>
  <style></style>
  